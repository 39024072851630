@import url('https://fonts.googleapis.com/css2?family=Rubik+Maps&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




#clock {
  font-size: 5em;
  font-weight: bold;
  font-family: 'DM Mono', sans-serif;
  background-color: #000a;
  border-radius: 10px;
  width: 448px;
  margin: 20px auto;
}

#hours, #minutes {
  color: #fff;

}
#time {
  color: #888;
}

#seconds {
  color: #ccc;
}

#events {
  overflow: hidden;
  color: linear-gradient(to bottom, #FFAF7B, #D76D77, #3A1C71);
  line-height: 0.8em;
}

p {
  font-size: 1.3em;
}



#today {
  color : white;
}

#tomorrow {
  color : #fffd;
}

#thisweek {
  color : #fffa;
}

#nextweek {
  color : #fff8;
}


li {
  display: list-item;
}



.App {
  position: fixed;
  width: 100vw;
  top: 30vh;
  height: 100vh;
}


body {
  margin: 0;
  font-family: 'Nexa Bold', sans-serif;
  font-weight: 200;
}


html {
  color: white;
  height: 100vh;
  background: #3A1C71;  /* fallback for old browsers */
}


html{
  background: linear-gradient(180deg, #ffaf7b, #d76d77, #3a1c71);
  background-size: 200% 200%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:51% 0%}
  50%{background-position:50% 100%}
  100%{background-position:51% 0%}
}
@-moz-keyframes AnimationName {
  0%{background-position:51% 0%}
  50%{background-position:50% 100%}
  100%{background-position:51% 0%}
}
@keyframes AnimationName {
  0%{background-position:51% 0%}
  50%{background-position:50% 100%}
  100%{background-position:51% 0%}
}